/** @jsxImportSource @emotion/react */
import logo from "./logo.svg";
import "./App.css";
import { Container, Button, TextField, Typography, Alert } from "@mui/material";
import { css } from "@emotion/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "@fontsource/balthazar";
import Torch from "./assets/antique_torch.jpg";
import React, { useState } from "react";

function App() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errMess, setErrMess] = useState("");

  async function handleSubmit(values) {
    await axios
      .post(
        "https://4gq05j38f0.execute-api.us-east-1.amazonaws.com/projekti-api-lambda",
        values
      )
      .then((response) => {
        setSuccessMessage("E-maili juaj sapo u dërgua!");
        console.log(successMessage);
      })
      .catch((error) => {
        setErrMess("Email-i juaj është i pavlefshëm");
        return error;
      })
      .finally(() => {
        setTimeout(() => {setSuccessMessage('')}, 5000)
        setTimeout(() =>  {setErrMess('')}, 5000)
      })
  }

  return (
    <Container
      css={css`
        display: flex;
        width: 100vw;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h1 style={{ fontFamily: "Balthazar" }}>PROJEKTI</h1>
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <Container
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <h2 style={{ fontFamily: "Balthazar" }}>
                  Për çdo të interesuar
                </h2>
                <Form
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  `}
                >
                  <Container
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    `}
                  >
                    {successMessage !== "" ? (
                      <Alert>{successMessage}</Alert>
                    ) : null}
                    {errMess !== "" ? <Alert severity="error">{errMess}</Alert> : null}
                    <label htmlFor="email">Email</label>
                    <Field
                      css={css`
                        border: 1px solid black;
                        border-radius: 8px;
                        width: min-content;
                      `}
                      type="email"
                      name="email"
                      id="email"
                    />
                    {errors.email && touched.email && (
                      <span>{errors.email}</span>
                    )}
                  </Container>
                  <Button type="submit" disabled={!(dirty && isValid)}>
                    Dërgo
                  </Button>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </Container>
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          max-height: 800px;
        `}
      >
        <img
          css={css`
            max-height: 75vh;
          `}
          src={Torch}
        />
      </Container>
    </Container>
  );
}

export default App;
